<template>
	<center class="field" v-if="users.length">
		<label class="label">Choisir un utilisateur</label>
		<div class="select">
			<select v-model="user" @change="setUser">
				<option v-for="user in users" :value="user.id">{{user.nom}}</option>
			</select>
		</div>
		<p v-if="user"><a class="button is-small is-text" @click="user=false;setUser()">Annuler</a></p>
	</center>
</template>
<script>
	export default {
		data() {
			return {
				user:false,
				users:[]
			}
		},
		mounted() {
			if(this.isAdmin) {
				this.api.get('users').then(response => {
					this.users = response.data.users;
					let tmp = localStorage.getItem('user');
					if(tmp && tmp != 'false') {
						this.user = tmp;
						this.setUser()
					}
				})
			}
		},
		methods : {
			setUser() {
				localStorage.setItem('user',this.user)
				this.$bus.$emit('setUser',this.user);
			}
		}

	}
</script>